import React from "react"
import style from "./Submenu.module.scss"
import slugify from "slugify"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"

const Submenu = ({ headings }) => (
  <div className={style.container}>
    <ol className={style.list}>
      {headings &&
        headings.map((heading, index) => (
          <li className={style.listItem} key={index}>
            <a
              href={`#${slugify(
                RichText.asText(heading).replace(/(&shy;|­|&#173;)/gi, ""),
                { lower: true }
              )}`}
              className={style.link}
              dangerouslySetInnerHTML={{ __html: RichText.asText(heading) }}
            />
          </li>
        ))}
    </ol>
  </div>
)

Submenu.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.array).isRequired,
}

export default Submenu
