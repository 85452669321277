import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const SEO = props => {
  const { description, lang, meta, title, image, slug } = props

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        /* Search Console Verification */
        {
          name: "google-site-verification",
          content: "1D5OQ1dh_324bc3mOcJFDlwcQTNT0d2uLmmePNZb0As",
        },
        /* Search Engine */
        {
          name: `description`,
          content: description,
        },
        {
          name: `image`,
          content: image?.url,
        },
        /* Schema.org for Google  */
        {
          itemprop: `name`,
          content: title,
        },
        {
          itemprop: `description`,
          content: description,
        },
        {
          itemprop: `image`,
          content: image?.url,
        },
        /* Open Graph general (Facebook, Pinterest & Google+) */
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image?.url,
        },
        {
          property: `og:url`,
          content: slug
            ? `https://www.lafresa.ch/${slug}/`
            : `https://www.lafresa.ch/`,
        },
        {
          property: `og:site_name`,
          content: slug ? slug.toUpperCase() : "HOME PAGE",
        },
        /* Twitter */
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image:src`,
          content: image?.url,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `de-ch`,
  meta: [],
  description: ``,
  slug: "",
  title: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  slug: PropTypes.string,
}

export default SEO
